<!--权限控制-角色管理-->
<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter'>
    <el-form-item>
      <el-input @change="hasChange" size='mini' v-model.trim='filter.Name' placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button></el-input>
    </el-form-item>
    <el-form-item>
      <el-button size='mini' type='primary' @click='handleAdd'>新增</el-button>
    </el-form-item>
  </el-form>
  </el-col>
  <!-- 列表 -->
  <el-col :span='24'>
  <el-table :data='datalist.content' size='mini' border stripe v-loading='loading' style='width: 100%;'>
    <el-table-column type='index' align='center' label="#" width='50'></el-table-column>
    <el-table-column prop='Name' header-align='center' label='名称' width='120' align='center'>
    </el-table-column>
    <el-table-column prop='Sign' align='center' label='权限标识' width='120' header-align='center'>
    </el-table-column>
    <el-table-column prop='ETable' align='center' label='实体表' width='120' header-align='center' />
    <el-table-column prop='Typ' :formatter="typFormatter"  label='用户类型' width='120' align='center' header-align='center' />
    <!-- <el-table-column prop='RoleType' align='center' label='用户类型' width='120' align='center' header-align='center'>
    </el-table-column>
    <el-table-column prop='status' align='center' label='状态' width='120' align='center' header-align='center'>
    </el-table-column>
    <el-table-column prop='create_time' header-align='center' label='创建日期' width='150'>
    </el-table-column>
    <el-table-column prop='modify_time' header-align='center' label='修改日期' width='150'>
    </el-table-column>
    <el-table-column prop='creator' header-align='center' label='创建者' width='74'>
    </el-table-column>
    <el-table-column prop='modifier' header-align='center' label='修改者' width='74'>
    </el-table-column> -->
    <el-table-column prop='Remark' header-align='center' label='备注' width='120' align='center'>
    </el-table-column>
    <el-table-column align='center' label='操作' width='137' >
    <template slot-scope='scope'>
      <i title='分配权限' @click='handleAuthAssign(scope.$index, scope.row)' class='opt fa fa-shield' />
      <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' />
      <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete' />
    </template>
    </el-table-column>
    </el-table>
  </el-col>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-pagination background @size-change='handleSizeChange' :current-page.sync='filter.page' @current-change='handleCurrentChange' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total'>
      </el-pagination>
    </el-col>

    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose'>
      <!--编辑界面-->
      <template v-if='dlg.type===1'>
        <el-form :model='formData' label-width='80px' :rules='formRule' ref='formData' size='mini'>
          <el-form-item prop='Name' label='名称' >
            <el-input @change="hasChange" v-model.trim='formData.Name' ></el-input>
          </el-form-item>
          <el-form-item prop='ETable' label='实体表'>
            <el-input @change="hasChange" v-model.trim='formData.ETable' ></el-input>
          </el-form-item>
          <el-form-item prop='Sign' label='权限标识'>
            <el-input @change="hasChange" v-model.trim='formData.Sign' ></el-input>
          </el-form-item>
          <!-- <el-form-item prop='status' label='状态' >
            <el-radio v-model.trim="formData.status" size="mini" :label="1" >启用</el-radio>
            <el-radio v-model.trim="formData.status" size="mini" :label="0" >禁用</el-radio>
          </el-form-item> -->
          <el-form-item prop='Typ' label='类型' >
            <el-select @change="hasChange" v-model.trim='formData.Typ' clearable placeholder='请选择'>
              <el-option
                v-for="item in options"
                :key="item.K"
                :label="item.V"
                :value="item.K">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop='Remark' label='备注' >
            <el-input @change="hasChange" v-model.trim='formData.Remark' ></el-input>
          </el-form-item>
        </el-form>
        <div slot='footer'>
          <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
          <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
        </div>
      </template>
      <!--分配权限-->
      <template v-else>
        <el-tabs v-model="tabIndex" type="border-card">
          <el-input size="mini"
            placeholder="关键字"
            v-model="filterText" />
          <el-tab-pane
            v-for="(v, i) in tabs"
            :key="i"
            :label="v">
            <el-tree
              accordion
              :ref="'tree'+i"
              node-key="Id"
              :show-checkbox="true"
              :filter-node-method="filterNode"
              :props="props"
              :data="resource[i]"/>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer">
          <el-button size="mini" @click.native="handleClear">清 空</el-button>
          <el-button size="mini" type="primary" @click.native="handleResourceSave">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { treeFilter, referToMap } from '@/util/index'

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      loading: false,
      datalist: [],
      resource: [],
      options: [],
      selectedRole: '',
      tabIndex: 0,
      referMap: {},
      filterText: '',
      tabs: [
        '移动端权限',
        'PC端权限'
      ],
      dlg: {
        visible: false,
        title: '',
        type: 1,
        roleId: ''
      },
      filter: {
        Name: '',
        field: 'Name',
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 50]
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: '名称不可为空', trigger: 'blur' }],
        ETable: [{ required: true, message: '实体表名不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['props'])
  },
  watch: {
    filterText(val) {
      this.$refs.popTree.filter(val)
    }
  },
  created() {
    this.$post('admin/listReferParam', { code: 'ROLE_TYPE' }).then(res => {
      this.options = res
      this.referMap = referToMap(res)
    })
    this.$post('admin/listCaResource').then((res) => {
      const app = []
      const pc = []
      res.forEach(item => {
        if (item['Typ'] === 3) {
          app.push(item)
        } else {
          pc.push(item)
        }
      })
      this.resource.push(treeFilter(app))
      this.resource.push(treeFilter(pc))
    })
    this.getData()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.Name.indexOf(value) !== -1
    },
    typFormatter(r, c) {
      return this.referMap[r[c.property]]
    },
    handleClear() {
      this.$refs['tree' + this.tabIndex][0].setCheckedKeys([])
    },
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.loading = true
      var para = { StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size }
      para.Param = {}
      if (this.filter.Name) {
        para.Param[this.filter.field] = this.filter.Name
      }
      this.$post('admin/listCaRole', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.Name = null
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.pageSize = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.type = 1
      this.dlg.visible = true
      this.formData = {}
    },
    handleEdit: function(index, row) {
      this.dlg.title = '编辑'
      this.dlg.type = 1
      this.dlg.visible = true
      this.index = index
      this.formData = Object.assign({}, row)
    },
    handleAuthAssign(index, row) {
      this.dlg.title = '分配权限'
      this.dlg.type = 0
      this.selectedRole = row.Id
      this.$get('admin/listCaRoleResource', { roleId: this.selectedRole }).then(res => {
        this.$refs.tree0[0].setCheckedKeys(res)
        this.$refs.tree1[0].setCheckedKeys(res)
      })
      this.dlg.visible = true
    },
    handleResourceSave() {
      const res = this.$refs.tree0[0].getCheckedNodes(false, true)
      res.push(...this.$refs.tree1[0].getCheckedNodes(false, true))
      if (res.lenght !== 0) {
        const resId = []
        res.forEach(item => {
          resId.push(item['Id'])
        })
        this.$post('admin/saveCaRoleResource', { roleid: this.selectedRole, res: resId.join(',') }).then(res => {
          this.dlg.visible = false
        })
      }
    },
    handleDel: function(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const para = { id: row.Id }
        this.$get('admin/delCaRole', para).then(() => {
          this.datalist.content.splice(index, 1)
        })
      }).catch(() => {})
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveCaRole', para).then(() => {
              this.dlg.visible = false
            })
          }).catch(() => {})
        }
      })
    },
    handlerClose: function(index, row) {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      if (this.$refs['formData']) {
        this.$refs['formData'].resetFields()
      }
    }
  }
}
</script>
